const lng = function() {
    return document.location.pathname.split('/')[1] || 'en';
};

export default [
    {
        id: "crossales",
        name: "Home",
        path: "/home"
    },
];
