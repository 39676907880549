export default {
    namespaced: true,

    state: () => ({
        preorders: [],
        preorder: false,
    }),
    mutations: {
        setPreorders(state, preorders) {
            state.preorders = preorders;
        },
        setPreorder(state, preorder) {
            state.preorder = preorder;
        }
    },
    actions: {
        getPreorders(store) {
            return new Promise((resolve => {
                WV.Api.getPreorders()
                    .then(response => {
                        store.commit('setPreorders', response.data);
                        resolve();
                    });
            }));
        },
        postPreorderAcceptance(store, acceptance) {
            return new Promise((resolve => {
                WV.Api.postPreorderAcceptance(acceptance)
                    .then(response => {
                        resolve();
                    });
            }));
        },
    },
    getters: {

    }
};
