<template>
    <div class="layout__container">


        <template v-if="!$route.meta.isInternal">
            <BaseAuth></BaseAuth>
        </template>

        <template v-if="$route.meta.isInternal">

            <div class="layout__wrapper">

                <Header></Header>

                <router-view></router-view>

                <Footer></Footer>

            </div>

        </template>

    </div>
</template>

<script>
import Header from '@/views/Layout/Header.vue';
import Footer from '@/views/Layout/Footer.vue';
import BaseAuth from '@/views/Auth/BaseAuth.vue';

export default {
    name: 'App',
    components: {
        Header,
        Footer,
        BaseAuth,
    },
    beforeMount() {
        let hasToken = localStorage.hasOwnProperty('token');
        if (!hasToken && !WV.isAuthRoute()) {
            this.$router.push('/auth/login');
        }
    },
    mounted() {
    },
};
</script>

<style lang="scss">

@import './scss/utils/mixins.scss';
@import './scss/utils/variables.scss';

.layout {
    &__container {
        width: 100%;
        min-height: 100vh;
        height: 100%;
        display: flex;
        flex-direction: row;
    }

    // Wrapper
    &__wrapper {
        width: 100%;
        height: 100%;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $color-day-bg;
        padding: 0 10px 30px;
        font-family: 'Montserrat';
        font-style: normal;
        color: $color-day-dark-gray;
    }
    &__content {
        width: 100%;
        height: 100%;
        min-height: 100%;
        flex: 1;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: stretch;
        align-content: flex-start;
        color: $color-day-black;
        position: relative;
        padding-top: 90px;
    }
}

</style>
