<template>
    <div
        class="form-input"
        :class="{'form-input--error': hasValidationErrors}">

        <label
            v-if="label"
            class="form-input__label">
            {{ label }}
            <span
                v-if="tooltip"
                :data-tippy-content="tooltip"
                class="form-input__tooltip">
                <SvgUse
                    :width="14"
                    :height="14"
                    id="help"
                ></SvgUse>
            </span>
        </label>

        <div class="form-input__wrapper">
            <div
                v-if="withIcon"
                class="form-input__icon">
                <SvgUse
                    :width="20"
                    :height="20"
                    :id="iconId"
                ></SvgUse>
            </div>

            <input
                ref="input"
                :type="type"
                :disabled="disabled"
                autocomplete="nope"
                class="form-input__control"
                :class="{'form-input__control-iconized': withIcon}"
                :placeholder="placeholder"
                :value="modelValue"
                @paste.prevent="onPaste"
                @input="updateValue">
        </div>

        <div
            v-if="hasValidationErrors"
            class="form-input__notice">
            <span
                v-for="validation in validations"
                v-show="!validation.rule">
                {{ validation.message }}.
            </span>

        </div>

    </div>
</template>

<script>
module.exports = {
    name: 'FormInput',
    data() {
        return {
            hasValidationErrors: false
        };
    },
    model: {
        prop: 'modelValue',
        event: 'update'
    },
    props: {
        label: {
            type: [String, Boolean],
            default: 'Label'
        },
        autofocus: {
            default: false,
            type: Boolean,
        },
        tooltip: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        modelValue: {
            default: ''
        },
        disabled: {
            default: false,
            type: Boolean,
        },
        withIcon: {
            default: false,
            type: Boolean,
        },
        iconId: {
            type: String,
            default: ''
        },
        validations: {
            type: Array,
            default() {
                return [];
            }
        },
        type: {
            type: String,
            default: 'text'
        },
    },
    computed: {},
    watch: {
        modelValue(newVal, oldVal) {
            if (newVal !== oldVal) this.checkValidationErrors();
        }
    },
    methods: {
        updateValue(event, oldVal) {
            this.$emit('update:modelValue', event.target.value);
            this.$emit('changed');
            this.checkValidationErrors();
        },
        keyup() {
            this.checkValidationErrors();
            this.$emit('keyup');
        },
        checkValidationErrors() {
            this.hasValidationErrors = false;
            this.validations.forEach(validation => {
                if (!validation.rule) {
                    this.hasValidationErrors = true;
                }
            });
        },
        disableValidation() {
            this.hasValidationErrors = false;
        },
        onPaste(event) {
            console.log('onPaste');
            this.$emit('update:modelValue', event.clipboardData.getData('text').trim());
            this.$emit('changed');
        },
        setFocusIfNeed() {
            if (this.autofocus) {
                this.$refs.input.focus();
            }
        },
    },
    mounted() {
        WV.Bus.on('fireValidations', this.checkValidationErrors);
        WV.Bus.on('disableValidations', this.disableValidation);
        this.setFocusIfNeed();
    }
};
</script>

<style lang="scss">

@import '../scss/utils/mixins.scss';
@import '../scss/utils/variables.scss';

.form-input {
    width: 100%;
    padding: 0;
    position: relative;
    height: 50px;
    &__label {
        position: absolute;
        z-index: 9;
        bottom: calc(100% - 10px);
        left: 5px;
        padding: 0 5px;
        border-radius: 5px;
        background: white;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        font-family: $font-secondary;
        font-weight: 300;
        font-size: 13px;
        line-height: 120%;
        height: 20px;
        letter-spacing: 0.5px;
        color: $color-text-muted;
    }
    &__tooltip {
        margin-left: 7px;
        height: 14px;
        width: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__wrapper {
        position: relative;
        height: 50px
    }
    &__icon {
        position: absolute;
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__control {
        width: 100%;
        height: 50px;
        font-family: $font-secondary;
        font-size: 14px;
        line-height: 16px;
        padding-left: 20px;
        border: 1px solid $color-day-line;
        border-radius: 3px;
        letter-spacing: .5px;
        color: $color-text-default;
        &:disabled{
            background-image: linear-gradient(129deg,#fff,#f5f5f5);
        }
        &-iconized {
            padding-left: 50px;
        }
    }
    &__notice {
        position: absolute;
        top: 100%;
        min-height: 30px;
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
        font-family: $font-secondary;
        letter-spacing: 0.02em;
    }
    &--error {
        .form-input__control {
            border: 1px solid $color-day-error-dark;
        }
        .form-input__notice {
            color: $color-day-error-dark;
        }
    }
}
</style>
