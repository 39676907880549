import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'login',
        component: () => import(/* webpackChunkName: "auth" */'../views/Auth/Login.vue'),
        meta: {
            isInternal: false,
            title: 'Login'
        }
    },
    {
        path: '/home',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */'../views/Home/Home.vue'),
        meta: {
            isInternal: true,
            title: 'Home'
        }
    },
    {
        path: '/preorders',
        name: 'preorders',
        component: () => import(/* webpackChunkName: "preorders" */'../views/Preorders/Preorders.vue'),
        meta: {
            isInternal: true,
            title: 'Предзаказы'
        }
    },
    {
        path: '/preorders/acceptance',
        name: 'preorder-acceptance',
        component: () => import(/* webpackChunkName: "preorders" */'../views/PreorderAccept/PreorderAccept.vue'),
        meta: {
            isInternal: true,
            title: 'Приемка предзаказа'
        }
    },
    {
        path: '/dispatch',
        name: 'dispatch',
        component: () => import(/* webpackChunkName: "dispatch" */'../views/Dispatch/Dispatch.vue'),
        meta: {
            isInternal: true,
            title: 'Отгрузка'
        }
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: { path: "/" },
        name: '404',
        component: () => import(/* webpackChunkName: "auth" */'../views/Auth/Login.vue'),
        meta: {
            isInternal: false,
            title: 'Error 404'
        }
    },

];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
