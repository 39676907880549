<template>
    <header class="Header">
        <div class="Header__wrapper">

            <div
                class="Header__burger"
                @click="$router.push('/home')">
                <SvgUse id="burger" :width="32" :height="32"></SvgUse>
            </div>

            <div class="Header__avatar">
                <div v-if="false" class="Header__avatar__notification">5</div>
                <AvatarLetter
                    :size="45"
                    :words="['Y','S']"
                ></AvatarLetter>
            </div>
        </div>
    </header>
</template>

<script>
    export default {
        name: 'Header',
        props: [],
        data() {
            return {};
        },
        computed: {
            user() {
                return this.$store.state.auth.user
            },
            preparedAvatarWords() {
                let words = [];
                let firstName = typeof this.user.first_name !== 'object' && this.user.first_name !== ''
                    ? this.user.first_name.replaceAll(' ', '')
                    : ''
                let lastName = typeof this.user.last_name !== 'object' && this.user.last_name !== ''
                    ? this.user.last_name.replaceAll(' ', '')
                    : ''
                firstName+lastName !== ''
                    ? words.push(firstName+' '+lastName)
                    : words.push(this.user.username.replaceAll(' ', ''))
                return words
            }
        },
        methods: {},
        beforeMount() {},
        components: {}
    };
</script>

<style lang="scss">

    @import '../../scss/utils/mixins.scss';
    @import '../../scss/utils/variables.scss';

    .Header{
        position: relative;
        width: 100%;
        height: 70px;
        &__wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 70px;
            position: absolute;
            top:0;
            left: -10px;
            right: -10px;
            padding: 0 20px;
            background-color: $color-day-white;
            transform: translateY(0);
            transition: transform 0.2s;
            z-index: $z-index-menu - 1;
        }
        &__burger{
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
        &__avatar {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex:none;
            position: relative;
            z-index: $z-index-avatar;

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }

            &__notification {
                width: 20px;
                height: 20px;
                background: $color-day-error;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: -7px;
                right: -7px;
                z-index: $z-index-avatar + 1;
                font-weight: bold;
                font-size: 12px;
                line-height: 14px;
                letter-spacing: 0.5px;
                color: $color-day-white;
            }
        }
    }

</style>
