export default {
    namespaced: true,

    state: () => ({
        products: [],
    }),
    mutations: {
        setProducts(state, products) {
            state.products = products;
        },
    },
    actions: {
        getProducts(store, gtins) {
            return new Promise((resolve => {
                WV.Api.getProducts(gtins)
                    .then(response => {
                        store.commit('setProducts', response.data);
                        resolve();
                    });
            }));
        },
    },
    getters: {

    }
};
