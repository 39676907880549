<template>
    <div
        class="card"
        :class="addclass">
        <div class="card__heading">
            <slot name="header"></slot>
        </div>
        <div class="card__content" :class="{'pb-0':removePB}">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Card',
        props: {
            title: {
                type: String,
                default: ''
            },
            addclass: {
                type: String,
                default: ''
            },
            removePB: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {};
        },
        computed: {},
        methods: {},
        beforeMount() {
        }
    };
</script>

<style lang="scss">

    @import '../scss/utils/mixins.scss';
    @import '../scss/utils/variables.scss';

    .card{
        width: 100%;
        flex:none;
        border: none;
        background-color: #ffffff;
        border-radius: 10px;
        &__content{
            padding: 20px;
        }
        &__separator{
            height: 2px;
            background: $color-day-bg;
            margin: 0 -30px 0;
        }
    }
</style>
