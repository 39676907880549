<template>
    <footer class="layout__footer">
        <!-- Footer copy -->
        <div class="layout__footer_copy-block">
            <a href="#" class="layout__footer_logo">
<!--                <img src="/images/footer-webvork-logo.svg" alt="Webvork logo"/>-->
            </a>
        </div>
    </footer>
</template>

<script>
module.exports = {
    name: 'Footer',
    props: [],
    data() {
        return {};
    },
    computed: {
    },
    methods: {},
    beforeMount() {
    }
};
</script>

<style lang="scss">
@import '../../scss/utils/mixins.scss';
@import '../../scss/utils/variables.scss';

.layout__footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 60px;
    margin-top: auto;
    position: relative;
    justify-content: center;
    a {
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }
    a:hover {
        color: $color-day-black;
    }
    &_logo {
        margin-bottom: 30px;
    }

}
</style>
